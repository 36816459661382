import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";

const StyledWrapper = styled.div``;

const UpreachAssociateUserAgreement = () => {
  return (
    <Layout>
      <StyledWrapper>
        <div className="container mt-5">
          <h1 className="text-center my-5">upReach Associate User Agreement</h1>
          <p>
            <strong>1. Purpose.</strong>
          </p>
          <p>
            This upReach Associate User Agreement (“Agreement”) sets forth
            upReach Charitable Company’s ("<strong>upReach</strong>"
            <strong>,</strong> "<strong>we</strong>", "<strong>our</strong>","
            <strong>or</strong>","<strong>us</strong>") practices regarding the
            collection and processing of individually identifiable data from
            prospective applicants (“Applicant(s)”), individuals accepted into
            upReach’s programme (“<strong>Associate(s)</strong>”), or upReach
            Associates who have transitioned into alumni status (“
            <strong>Alumni</strong>”) (each, an “<strong>Upreach User,</strong>”
            collectively, the “<strong>Upreach Users,</strong>” “
            <strong>you</strong>” or “<strong>your</strong>”).
          </p>
          <p>
            upReach collects and retains certain information about individuals
            using, or attempting to use, upReach’s services. We collect this
            data for various purposes and at different times in furtherance of
            upReach’s mission, purpose, and services, as detailed in this
            Agreement. This Agreement should be read in conjunction with our
            Privacy Notice, which can be found at https://upreach.org.uk.
          </p>
          <p>
            <strong>2. Consent.</strong>
          </p>
          <p>
            <i>
              By applying to become an upReach Associate, or providing upReach
              with personal data, you consent to the terms of this Agreement,
              upReach’s processing of your personal data (as further detailed in
              this Agreement), and the purposes for which your personal data is
              processed. You understand and agree that upReach will process
              personal data and certain types of special category data to
              deliver services to you and our Partners (as defined herein), as
              well as to measure, evaluate, and report upReach’s services and
              impact. You also understand and agree that upReach’s processing of
              personal data and special category data includes your time as an
              Applicant, Associate, or as an Alumni.
            </i>
          </p>
          <p>
            <i>
              Notwithstanding, there are occasions where you may not want us to
              share certain data with upReach partner employers or partner
              universities (each, a “<strong>Partner</strong>,” and collectively
              the “<strong>Partners</strong>”). There may also be instances
              where you choose to withdraw consent to process data, where you
              previously provided such consent to upReach. It is within your
              legal right to withdraw such consent at any time. To withdraw
              consent, or to request certain information not be processed or
              shared, please send the full details of your request to Gavin
              Davis, at{" "}
            </i>
            <a href="https://web.archive.org/web/20200806073039/mailto:gavin.davis@upreach.org.uk">
              <i>gavin.davis@upreach.org.uk</i>
            </a>
            <i>.</i>
          </p>
          <p>
            <i>
              Providing consent to process data is not a precondition to
              service. Even still, choosing to withhold consent, withdraw
              consent, or request that certain data not be processed may limit
              upReach’s ability to provide services.
            </i>
          </p>
          <p>
            <strong>3. Data Controller Information and Principles.</strong>
          </p>
          <p>
            upReach, located at Ground Floor, Studio 18, Blue Lion Place, 237
            Long Lane, London, SE1 4PU, the Chief Executive Officer, and the
            Trustees are the “Data Controller” as the term is both defined in
            this Agreement and by the General Data Protection Regulation (“
            <strong>GDPR</strong>”). upReach has designated Gavin Davis, as the
            person responsible for data protection matters at upReach. Her
            contact details can be found on page 5 of this Agreement.
          </p>
          <p>
            upReach is committed to ensuring that any information provided by
            Applicants, Associates, and Alumni (either directly or indirectly)
            is collected, processed, and protected in accordance with all
            applicable laws. Such laws include the Data Protection Act 2018 and
            GDPR, including Article 5 of GDPR that requires personal data be:
          </p>
          <ul>
            <li>Processed lawfully, fairly, and in a transparent manner;</li>
            <li>
              Collected for specified, explicit, and legitimate purposes and
              that further processing of personal data is not incompatible with
              the purposes stated in this Agreement or our Privacy Policy;
            </li>
            <li>
              Adequate, relevant, and limited to what is necessary in relation
              to the purposes for which the personal data is processed;
            </li>
            <li>
              Accurate and, where necessary, kept up to date, whilst taking
              reasonable steps to ensure inaccurate personal data is erased or
              rectified without delay (when considering the purpose(s) for which
              the personal data is processed);
            </li>
            <li>
              Kept in a form that permits identification only so long as
              necessary for the purposes described in this Agreement; and
            </li>
            <li>
              Both processed securely and protected using appropriate technical
              or organisational measures.
            </li>
          </ul>
          <p>
            upReach, its staff, and its data processors processing personal data
            shall comply with these principles at all times.
          </p>
          <p>
            <strong>4. Information Collection and Use.</strong>
          </p>
          <p>
            Subject to this Agreement, upReach will treat as confidential the
            personal data that it collects about upReach Users.
          </p>
          <p>
            <i>A. Collection of Personal Data and Special Category Data.</i>
          </p>
          <p>
            The categories of personal data we may collect include, without
            limitation:
          </p>
          <ul>
            <li>Name(s);</li>
            <li>Gender;</li>
            <li>Date of birth;</li>
            <li>
              Educational details including educational institutions, modules,
              and grades;
            </li>
            <li>
              Personal and university email addresses, Skype identification
              information, phone number(s), and postal address(es);
            </li>
            <li>
              Social media profiles and identification including profiles on
              Facebook, LinkedIn, and WhatsApp;
            </li>
            <li>Free school meals eligibility status;</li>
            <li>Refugee or asylum seeker status;</li>
            <li>Time in care;</li>
            <li>Caring responsibilities;</li>
            <li>
              Parent(s), carer(s), or guardian(s) occupation, university status,
              or both;
            </li>
            <li>Mitigating circumstances;</li>
            <li>
              Household income and university financial information (as those
              terms are defined by each upReach User’s applicable university or
              institution);
            </li>
            <li>Bank details; or</li>
            <li>Your photo.</li>
          </ul>
          <p>
            Likewise, we only collect certain ‘special category’ data that
            includes information about:
          </p>
          <ul>
            <li>Race;</li>
            <li>Ethnic origin;</li>
            <li>Religion;</li>
            <li>Health; or</li>
            <li>
              Sexual orientation.
              <br />
              <i>
                B. Purposes of Use - Personal Data and Special Category Data.
              </i>
            </li>
          </ul>
          <p>
            The purposes for which upReach processes, uses, and shares upReach
            Users’ personal data (including special category data) is both set
            forth below and in our Privacy Policy. By accepting the terms of
            this Agreement, providing us with personal data, or both, you
            understand and agree that upReach will process personal data in
            accordance with this Agreement to deliver services, and to liaise
            with upReach’s Partner organisations. Where possible, upReach
            removes personally identifiable data, and upReach will always obtain
            agreement from upReach users, prior to providing Partners with
            personalised upReach User case studies.
          </p>
          <p>
            upReach may share information about upReach Users including, without
            limitation:
          </p>
          <ul>
            <li>
              Informing an upReach User’s university about his or her interest
              in, or progress through, upReach’s programme;
            </li>
            <li>
              Informing Partners about an upReach User’s status and engagement
              with our programme, including use of our resources and outcomes,
              mentor details, or attendance at upReach events;
            </li>
            <li>
              Sharing the data upReach Users provide to us when completing
              REALrating, getEmployable, or both, with Partners (including
              Indicators of Disadvantage (as defined below));
            </li>
            <li>
              Informing a partner employer of an Associate’s background and
              progress through the partner employer’s application process(es),
              including interview responses, application outcomes, and upReach
              recommendations for supporting an Associate’s application;
            </li>
            <li>
              Sharing information with a partner employer who has requested
              information to help inform their recruitment decision about an
              Associate; or
            </li>
            <li>
              Reporting diversity data to partner universities about their
              cohort of Associates.
            </li>
          </ul>
          <p>
            <strong>
              5. upReach’s Legal Bases for Processing Personal Data.
            </strong>
          </p>
          <p>
            In accordance with Article 6 of GDPR, we rely on certain legal bases
            to process your data. Your consent (as demonstrated by accepting the
            terms of this Agreement, providing upReach with your personal data,
            or both) is the primary legal basis permitting upReach to process
            the personal data you provide to us.
          </p>
          <p>
            We may also rely on two (2) other legal bases under Article 6 to
            process your data: (i) legal obligations (such as legal or
            regulatory requirements, like court orders); or (ii) a contractual
            basis, such as this Agreement, where you and upReach contractually
            agree to certain obligation and rights. It is important to note that
            upReach is obliged to abide by all legal requests for information
            made by law enforcement, judicial bodies, or other applicable legal
            or regulatory bodies.
          </p>
          <p>
            We process special category data in accordance with Article 9 of
            GDPR. This includes processing special category data based on your
            explicit consent, as well as processing this data as part of our
            legitimate work as a not-for-profit organisation.
          </p>
          <p>
            <strong>6. upReach Obligations.</strong>
          </p>
          <p>
            All upReach staff have some responsibility for ensuring data is
            processed and protected in accordance with applicable laws and
            regulations, and receive training accordingly. upReach staff ensure
            upReach Users’ data is secured, restricted, and shared in accordance
            with the terms of this Agreement and only for the purposes
            stipulated in this Agreement.
          </p>
          <p>
            upReach’s Product Manager and its Legal and Risk Management ensure
            all systems, services, and equipment used to process and protect
            personal data meet acceptable security standards and perform regular
            checks to ensure proper functionality. The CEO and Legal and Risk
            Management also ensure compliance with the terms of this Agreement.
          </p>
          <p>
            <strong>7. Associate Obligations.</strong>
          </p>
          <p>
            upReach Users are responsible for ensuring personal data provided to
            upReach is accurate and up-to-date.
          </p>
          <p>
            <strong>8. Right to Access and Accuracy Personal Data.</strong>
          </p>
          <p>
            You are entitled to request and access the information that upReach
            holds about you (subject to limited exceptions), as stated in the
            Data Protection Act 2018, GDPR, and other applicable laws and
            regulations. In addition, you are entitled to have inaccurate
            personal data corrected or erased, and to object to the processing
            of your personal data. If you wish to access such personal data, you
            should send your request via email to Gavin Davis, at{" "}
            <a href="https://web.archive.org/web/20200806073039/mailto:gavin.davis@upreach.org.uk">
              gavin.davis@upreach.org.uk
            </a>
            . If upReach becomes aware of an inaccuracy in your personal data,
            upReach will take every reasonable step to correct the inaccuracy.
          </p>
          <p>
            You have other rights related to your data as well. These rights
            include the right to:
          </p>
          <ul>
            <li>Request access to your personal data;</li>
            <li>Object to the processing of your personal data;</li>
            <li>Request restriction of processing your personal data; and</li>
            <li>Request transfer of your personal data.</li>
          </ul>
          <p>
            <strong>9. Retention of Personal Data.</strong>
          </p>
          <p>
            upReach will only retain the minimum necessary personal data about
            you, and only for so long as it is required to either: (i) fulfil
            the purposes stated in this Agreement; or (ii) maintain personal
            data to comply with applicable laws and regulations. Data will be
            erased in line with upReach’s normal data retention policy. In
            particular:
          </p>
          <p>
            <i>A. Erasure of Data for Unsuccessful Applicants.</i> Unsuccessful
            Applicants are those Applicants who are unsuccessful in becoming
            upReach Associates for any reason, including personal withdrawal
            from the application process. All personal data, including any
            special category data, belonging to unsuccessful Applicants will be
            deleted annually on 15th July.
          </p>
          <p>
            <i>B. upReach Associates and Alumni.</i> upReach will automatically
            erase all Associate and Alumni personal data and special category
            data five (5) years after the upReach Associate or Alumni leaves
            upReach’s programme. Any Alumni or Associate may also request
            personal data be erased by following the procedure indicated in this
            Agreement.
          </p>
          <p>
            <strong>10. Security.</strong>
          </p>
          <p>
            Any deliberate breach of data protection legislation, upReach data
            protection policies, or this Agreement may lead to disciplinary
            action being taken, up to and including dismissal from the
            organisation, legal action, or both. Any questions or concerns about
            the interpretation, application, or operation of this Agreement
            should be discussed with Legal and Risk Management.
          </p>
          <p>
            upReach is committed to following this Agreement and takes seriously
            any violations or breaches. If you feel that this Agreement has not
            been followed in respect of your personal data please contact Legal
            and Risk Management immediately per the contact information below.
          </p>
          <p>
            <strong>Role: Contact Information</strong>
          </p>
          <p>
            <strong>Data Controller: </strong>upReach Charitable Company
          </p>
          <p>
            <strong>Data Controller Designee: </strong>John Craven, CEO
          </p>
          <p>
            <strong>Data Controller Designee Email: </strong>
            <a href="https://web.archive.org/web/20200806073039/mailto:John@upreach.org.uk">
              John@upreach.org.uk
            </a>
          </p>
          <p>
            <strong>Data Protection Designee:</strong> Gavin Davis, Finance
            Manager
          </p>
          <p>
            <strong>Data Protection Designee Email: </strong>
            <a href="https://web.archive.org/web/20200806073039/mailto:gavin.davis@upreach.org.uk">
              gavin.davis@upreach.org.uk
            </a>
          </p>
          <p>
            <strong>Data Protection Designee Address: </strong>Ground Floor,
            Studio 18, Blue Lion Place, 237 Long Lane, London, SE1 4PU
          </p>

          <p>
            <strong>Glossary of Terms</strong>
          </p>
          <p>
            <strong>Consent.</strong> Freely given, specific, informed and
            unambiguous indication of a data subject’s wishes, whereby the data
            subject signifies by statement, or clear and affirmative action, his
            or her agreement to the processing of his or her personal data.
          </p>
          <p>
            <strong>Data.</strong> Any information which will be processed or
            used on or by a computerised system, including information contained
            within a “relevant filing system” of information.
          </p>
          <p>
            <strong>Data Subject.</strong> The person who is the subject of the
            “personal data.”
          </p>
          <p>
            <strong>Data Controller.</strong> A natural or legal person who
            determines (jointly or alone) the purposes and the manner in which,
            any personal data are, or will be, processed.
          </p>
          <p>
            <strong>Data Processor.</strong> Any person who processes data on
            behalf of the data controller.
          </p>
          <p>
            <strong>Indicators of Disadvantage.</strong> “Indicators of
            Disadvantage” include, without limitation, school performance,
            eligibility for free school meals, caring responsibilities, time in
            care, or postcode information. Limited Exceptions. Processing of
            personal and special category data including responding to a Subject
            Access Request may, in some circumstances, be restricted when
            personal data is subject to situations involving “crime and taxation
            purposes” which include, without limitation the:
          </p>
          <ul>
            <li>Prevention or detection of crime</li>
            <li>Capture or prosecution of offenders; or</li>
            <li>Assessment or collection of tax or duty.</li>
          </ul>
          <p>
            <strong>Personal Data. </strong>Any information relating to an
            identified or identifiable natural person (‘data subject’). An
            ‘identifiable natural person’ is one who can be identified, directly
            or indirectly, in particular by reference to an identifier such as a
            name, an identification number, location data, or an online
            identifier, or to one or more factors specific to the physical,
            physiological, genetic, mental, economic, cultural, or social
            identity of that natural person.
          </p>
          <p>
            <strong>Processing.</strong> Covers almost anything which is done
            with, or to, the data, including:
          </p>
          <ul>
            <li>obtaining data;</li>
            <li>recording or entering data onto the files;</li>
            <li>
              holding data, or keeping it on file without doing anything to it
              or with it;
            </li>
            <li>organising, altering, or adapting data in any way;</li>
            <li>retrieving, consulting, or otherwise using the data;</li>
            <li>disclosing data;</li>
            <li>combining data with other information;</li>
            <li>erasing or destroying data; or</li>
            <li>using the data within research.</li>
          </ul>
          <p>
            <strong>Recipient. </strong>Any person to whom personal data is
            disclosed, including any person to whom personal data is disclosed
            in the course of processing personal data for the data controller
            (e.g., an employee of the data controller, a data processor, or an
            employee of the data processor).
          </p>
          <p>
            <strong>Subject Access Request. </strong>The process by which
            individuals can determine what personal or special category data an
            organisation holds about them, why they hold it, and with whom the
            personal or special category data is shared.
          </p>
          <p>
            <strong>Third Party.</strong> A natural or legal person, public
            authority, agency, or body other than the data subject, data
            controller, data processor and persons who under the direct
            authority of the controller or processor authorised to process
            personal data.
          </p>
          <p>
            <strong>Name: </strong>Gavin Davis
          </p>
          <p>
            <strong>Email: </strong>
            <a href="https://web.archive.org/web/20200806073039/mailto:gavin.davis@upreach.org.uk">
              gavin.davis@upreach.org.uk
            </a>
          </p>
          <p>
            <strong>Phone: </strong>02030967893
          </p>
          <p>A-01 &nbsp; &nbsp; upReach Associate UserAgreement</p>
          <p>
            <strong>Prepared by:</strong> Nicola Lewis &amp; Stephanie Hudson
          </p>
          <p>
            <strong>Effective from:</strong> 27/11/2018
          </p>
          <p>
            <strong>Review on or before:</strong> 26/11/2019
          </p>
        </div>
      </StyledWrapper>
    </Layout>
  );
};

export default UpreachAssociateUserAgreement;
